<template>
  <div v-if="loading">Odota hetki, sinut ohjataan uuteen pikanäkymään.</div>
  <div v-else-if="error">
    <h2>
      PODIUM Connect® löytyy nyt uudesta osoitteesta. Kirjaudu sisään
      osoitteessa www.podium.fi
    </h2>
  </div>
  <div v-else>
    <div v-if="data">
      <h1>Odota hetki, sinut ohjataan uuteen pikanäkymään.</h1>
    </div>
    <div v-else>
      <div>
        <h2>
          PODIUM Connect® löytyy nyt uudesta osoitteesta. Kirjaudu sisään
          osoitteessa www.podium.fi
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const access_code = ref(route.params.access_code); // Access code as a ref

    const data = ref(null);
    const loading = ref(false);
    const error = ref(null);

    const fetchData = async (code) => {
      if (!code) {
        error.value = "error";
        return;
      }

      loading.value = true;

      try {
        const response = await fetch(
          `https://api.${process.env.VUE_APP_DOMAIN}/api/v2/calendar-shared-links/${code}/podium-ikkuna`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        console.log(response);
        data.value = await response.json();
      } catch (err) {
        error.value = err.message;
      } finally {
        if (data.value) {
            setTimeout(() => {
                const redirectUrl = `https://${process.env.VUE_APP_DOMAIN}/fi/pikanakyma/${data.value}`;
                window.location.href = redirectUrl;
                loading.value = false;
            }, [5000]);
        }
        
      }
    };

    onMounted(() => {
      fetchData(access_code.value); // Use .value to access the ref
    });

    watch(
      () => route.params.access_code,
      (newCode) => {
        access_code.value = newCode; // Use .value to update the ref
        fetchData(newCode);
      }
    );

    return {
      access_code,
      data,
      loading,
      error,
    };
  },
};
</script>

<style>
/* Simple styles for displaying data */
div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  font-family: Arial, sans-serif;
  flex-direction: column;
  text-align: center;
}
</style>
