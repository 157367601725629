

import { createRouter, createWebHistory } from 'vue-router';
import RedirectComponent from '../components/RedirectComponent.vue';

const routes = [
  {
    path: '/c/:access_code',
    name: 'Redirect',
    component: RedirectComponent,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
